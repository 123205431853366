export const EXPERIENCE_LIST = [
  {
    title: "Facebook",
    date: "Now",
    link: "https://www.facebook.com/careers",
    position: "Software Engineer",
    languages: [],
    tools: [],
    description: "Returning to the MPK office as a new grad engineer!!",
    image: require("../../static/experience/facebook.png"),
  },
  {
    title: "Instagram",
    date: "May - Aug 2020",
    link: "https://about.instagram.com/about-us/careers",
    position: "Software Engineer Intern",
    languages: ["Python", "PHP", "JavaScript"],
    tools: ["Django", "React", "GraphQL"],
    description:
      "On the Instagram APIs and Ads Product Platform team, I succeeded in integrating OAuth page linking into Ads Manager, allowing 7 million FB advertisers/day to securely connect to IG and display ads on both platforms. I also introduced multiple improvements to the Graph API, including expanding test coverage, updating public docs, and adding custom rate limiting to common API endpoints.",
    image: require("../../static/experience/instagram.png"),
  },
  {
    title: "Apple",
    date: "Jan - Apr 2020",
    link: "https://www.apple.com/jobs/us/about.html",
    position: "Software Engineer Intern",
    languages: ["Python", "JavaScript"],
    tools: [
      "Flask",
      "MongoDB",
      "Amazon S3",
      "React",
      "Electron",
      "Docker",
      "Kubernetes",
    ],
    description:
      "I skipped a semester of college to intern on the Wireless Tech & Ecosystems team in Cupertino. The first project I released was a cloud browsing platform for employees in USA, China, India, and Germany to organize and download 9500+ logging files. My second project was a multi-threaded app that automates instances of ML tasks on the cloud by restarting completed tasks adjusting resources.",
    image: require("../../static/experience/apple.png"),
  },
  {
    title: "Dataminr",
    date: "Jun - Aug 2019",
    link: "https://www.dataminr.com",
    position: "Software Engineer Intern",
    languages: ["JavaScript"],
    tools: ["Express", "React", "Redux", "Jest", "Enzyme"],
    description:
      "At Dataminr, I developed a file-sharing beta feature that automated alert-stream configurations for employees in four offices, decreasing their setup periods from 15 minutes to less than 15 seconds. Throughout the internship, I learned to manage API requests and Redux actions/reducers to control user information, permissions, and stream polling.",
    image: require("../../static/experience/dataminr.png"),
  },
  {
    title: "Little Caesars",
    date: "Jan - Dec 2019",
    link: "https://mdp.engin.umich.edu/about-mdp/",
    position: "Student Software Engineer",
    languages: ["Dart"],
    tools: ["Flutter", "MySQL", "DialogFlow"],
    description:
      "Under the University of Michigan's Multidisciplinary Design Program, I completed a year-long corporate-sponsored project with five other students. My team and I developed a natural language processing mobile application to interpret and respond to customers' intents. We continuously built prototypes and updated our sponsors at Little Caesars, using JIRA to manage tasks across the team.",
    image: require("../../static/experience/littleCaesars.png"),
  },
  {
    title: "The Michigan Daily",
    date: "Sept 2018 - Aug 2020",
    link: "https://www.michigandaily.com/",
    position: "Student Software Engineer",
    languages: ["Python", "JavaScript"],
    tools: ["Flask", "React", "SQL"],
    description:
      "The Michigan Daily is University of Michigan's oldest publication organization. As an engineer on the Web Team, I collaborated weekly with other students to create digital features for the main news website and GradeGuide, a online tool for comparing grade distribution data from courses. I was also the Recruitment Chair and led recruitment drives to grow the team.",
    image: require("../../static/experience/michiganDaily.png"),
  },
  {
    title: "Queensborough Community College",
    date: "June - Aug 2018",
    link: "https://www.qcc.cuny.edu/",
    position: "Web Development Volunteer",
    languages: ["HTML & CSS"],
    tools: ["WordPress"],
    description:
      "While taking summer classes at QCC, I volunteered at the Marketing & Communication Office to gain more experience in web development. I helped modernize the school's digital presence by rebuilding the art gallery site.",
    image: require("../../static/experience/qcc.png"),
  },
  // {
  //   title: "University of Michigan",
  //   date: "Sept 2017 - Apr 2021",
  //   link: "https://cse.engin.umich.edu/",
  //   position: "BSE in Computer Science",
  //   languages: [],
  //   tools: [],
  //   description:
  //     "Graduated with Magna Cum Laude from the University of Michigan College of Engineering. Completed courses include Web Systems, Databases, User Interfaces, Artificial Intelligence, and Information Retrieval.",
  //   image: require("../../static/experience/umich.png"),
  // },
];
